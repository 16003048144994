import React from "react";
import styles from "./chatComponent.module.css";
import { Placeholder } from "react-bootstrap";

function TempMessage({ isFirstMessage }) {
  return (
    <div
      className={styles.chatContainer}
      style={{ padding: isFirstMessage ? "0 40px" : "0 50px" }}
    >
      {isFirstMessage && (
        <div className={styles.chatPhoto}>
          <img
            className={styles.leftImg}
            src="https://ambitious-coast-0247bc410.5.azurestaticapps.net/img/logo144x144px.png"
            alt="User"
          />
        </div>
      )}

      <div className={styles.chatBalloon} style={{ width: "88%" }}>
        {isFirstMessage && (
          <img
            className={styles.pointLeft}
            src={require("../../assets/img/leftpoint.png")}
            alt="User"
          />
        )}
        <Placeholder as={"span"} animation="glow">
          <Placeholder xs={4} size="sm" /> <Placeholder xs={7} size="sm" />{" "}
          <Placeholder xs={3} size="sm" /> <Placeholder xs={8} size="sm" />{" "}      
        </Placeholder>

        <div className={styles.chatBalloonArrow}></div>

        <Placeholder as={"span"} animation="glow">
          <Placeholder
            style={{
              position: "absolute",
              bottom: 5,
              right: 17,
              zIndex: "55",
            }}
            xs={1}
            size="xs"
          />
        </Placeholder>
      </div>
    </div>
  );
}

export default TempMessage;
