import React, { useState, useEffect } from "react";

function Typewriter({ text, speed = 100, loop = false, cursor = false }) {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const [showCursor, setShowCursor] = useState(true);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (index < text.length) {
      timeoutId = setTimeout(() => {
        setDisplayedText(text.slice(0, index + 1));
        setIndex(index + 1);
      }, speed);
    } else if (loop && !completed) {
      setCompleted(true);
      setTimeout(() => {
        setDisplayedText("");
        setIndex(0);
        setCompleted(false);
      }, 1000);
    }

    return () => clearTimeout(timeoutId);
  }, [index, text, speed, loop, completed]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 500);

    return () => clearInterval(cursorInterval);
  }, []);

  return (
    <span>
      {displayedText}
      {cursor && showCursor && <span>|</span>}
    </span>
  );
}

export default Typewriter;
