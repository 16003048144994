import React from "react";
import styles from "./chatComponent.module.css";
import Typewriter from "../Typewriter";
import { formatTime } from "../../utils";
import { useSelector } from "react-redux";
import ReactMarkdown from 'react-markdown';

function ChatMessage({ message, isFirstMessage }) {
  const { isHistory } = useSelector((state) => state.chat);
  
  return (
    <div
      className={styles.chatContainer}
      style={{ padding: isFirstMessage ? "0 40px" : "0 50px" }}
    >
      {isFirstMessage && (
        <div className={styles.chatPhoto}>
          <img
            className={styles.leftImg}
            src="https://ambitious-coast-0247bc410.5.azurestaticapps.net/img/alfa.png"
            alt="User"
          />
        </div>
      )}

      <div className={styles.chatBalloon} style={{ background: "" }}>
        {isFirstMessage && (
          <img
            className={styles.pointLeft}
            src={require("../../assets/img/leftpoint.png")}
            alt="User"
          />
        )}
        {isHistory &&<ReactMarkdown>{message.message}</ReactMarkdown>}
        {!isHistory && <Typewriter speed={0.01} text={message.message} />}
        <div className={styles.chatBalloonArrow}></div>
        <span
          style={{
            fontSize: "10px",
            fontWeight: "400",
            color: "#000",
            position: "absolute",
            right: 15,
            bottom: 2.5,
          }}
        >
          {formatTime(message.date)}
        </span>
      </div>
    </div>
  );
}

export default ChatMessage;
