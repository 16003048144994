import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaBook, FaDollarSign, FaRobot } from "react-icons/fa";
import { motion } from "framer-motion";
import styles from "./navbar.module.css";
import RobotAnimation from "./robot.json";
import Lottie from "lottie-react";

export function SignedMenu() {
  const location = useLocation();
  const [isActive, setIsActive] = useState(location.pathname);

  useEffect(() => {
    setIsActive(location.pathname);
  }, [location]);

  const routes = [
    {
      name: "Generate Tasks",
      route: "/task",
      visible: true,
      icon: <FaRobot className={styles.navLinkIcon} />,
    },
    {
      name: "Billing",
      route: "/billing",
      visible: true,
      icon: <FaDollarSign className={styles.navLinkIcon} />,
    },
    {
      name: "Instructions",
      route: "/instructions",
      visible: true,
      icon: <FaBook className={styles.navLinkIcon} />,
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  const handleOpenFrame = (page) => {
    const t = window.TrelloPowerUp.iframe({
      appKey: "5a75b71bf26591fc4b0e75b4a30a5dde",
      appName: "Ezy Task Ai",
    });

    return t.modal({
      url: page.route,
      fullscreen: true,
      title: page.name,
    });
  };

  const options = {
    loop: true,
    autoplay: true,
    style: { width: 160, height: 160, margin: "auto" },
    animationData: RobotAnimation,
  };

  return (
    <div className={styles.container}>
      <motion.nav
        className={styles.navbar}
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.div
          style={{
            width: "100px",
            marginTop: "-20px",
            height: "135px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          variants={itemVariants}
        >
          <Lottie {...options} />
        </motion.div>
        <div style={{zIndex:5}}>
          {routes.map((r) =>
            r.visible ? (
              <motion.div
                key={r.route}
                className={styles.navItem}
                variants={itemVariants}
              >
                <motion.div
                  onClick={() => handleOpenFrame(r)}
                  className={`${styles.navLink} ${
                    isActive === r.route ? styles.navLinkActive : ""
                  }`}
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  }}
                  whileTap={{ scale: 0.9 }}
                >
                  {r.icon} {r.name}
                </motion.div>
              </motion.div>
            ) : null
          )}
        </div>
      </motion.nav>
    </div>
  );
}

export default SignedMenu;
