import SelectComponent from "../SelectComponent";
import styles from "./taskGeneratorComponent.module.css";
import "react-circular-progressbar/dist/styles.css";
import ButtonComponent from "../Button";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../assets/lottie/animation.json";
import Lottie from "lottie-react";
import { generateTasks } from "../../redux/reducers/trello/slice";
import { useNavigate } from "react-router-dom";
import SelectTaskComponent from "../SelectTaskComponent";
import { addMsg } from "../../redux/reducers/chat/slice";
import { promptValidation } from "../../utils";

const inputStyle = {
  width: "100%",
  height: "50px",
  border: "none",
  outline: "none",
  padding: "0 10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderTopLeftRadius: "3px",
  borderBottomLeftRadius: "3px",
  paddingRight: "170px",
};

export function TaskGeneratorComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { messages } = useSelector((state) => state.chat);
  const [listSelected, setListSelected] = useState("");
  const [prompt, setPrompt] = useState("");
  const [qntTasks, setQntTasks] = useState("");
  const { listsLoading, isGeneratingTasks } = useSelector((state) => state.trello);
  const { isAuth } = useSelector((state) => state.user);

  const options = {
    loop: true,
    autoplay: true,
    style: { width: 125, height: 125, margin: "auto" },
    animationData: Loading,
  };

  const handleOnChangeSelect = (e) => {
    setListSelected(e.target.value);
  };

  const handleOnChangeQntTasks = (e) => {
    const number = e.target.value ? parseInt(e.target.value) : "";
    setQntTasks(number);
  };

  const handleGenerateTask = () => {
    if (!isAuth) {
      navigate("/billing");
      return;
    }

    if(prompt){

    }

    const newMessage = {
      message: prompt,
      date: new Date(),
      by: "me",
    };
    setPrompt("");
    dispatch(addMsg(newMessage));
    dispatch(generateTasks({ prompt, listId: listSelected, qntTasks }));
  };

  const isChatVisible = messages.length > 0;
  const containerStyle = {
    margin: 0,
    zIndex: 2,
    position: "absolute",
    bottom: 0,
    borderRadius: 0,
    padding: "0 20px",
    paddingBottom: "8px",
    height: "75px",
    alignItems: "end",
    transform: isChatVisible ? "translateY(0)" : "translateY(-100vh)",
    transition: "transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
    boxShadow: "-8px 0 5px rgba(0, 0, 0, 0.25),  8px 0 5px rgba(0, 0, 0, 0.25)",
  };

  return listsLoading ? (
    <div>
      <div className="w-100 h-100 d-flex">
        <Lottie {...options} />
      </div>
    </div>
  ) : (
    <div
      className={styles.container}
      style={isChatVisible ? containerStyle : {}}
    >
      <div className="w-100 d-flex">
        <div className="w-100 px-0">
          <div className={styles.inputContainer}>
            <input
              type="text"
              placeholder="Your prompt here"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              style={inputStyle}
              disabled={isGeneratingTasks}
              maxLength={800}
            />
            <SelectComponent value={prompt} onChange={handleOnChangeSelect} />
            <SelectTaskComponent
              value={qntTasks}
              onChange={handleOnChangeQntTasks}
            />
            <ButtonComponent
              onClick={handleGenerateTask}
              disabled={
                !promptValidation(prompt) ||
                !listSelected ||
                !qntTasks ||
                isGeneratingTasks
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
