import React from "react";
import styles from "./chatComponent.module.css";
import { useSelector } from "react-redux";
import myImg from "../../assets/img/myphoto.png";
import { formatTime } from "../../utils";

function MyMessage({ message, isFirstMessage }) {
  const { image } = useSelector((state) => state.trello);
  const userPhoto = image ? image + "/170.png" : myImg;

  return (
    <div
      className={styles.chatContainer}
      style={{ padding: isFirstMessage ? "0 40px" : "0 50px" }}
    >
      <div
        className={styles.chatBalloon}
        style={{
          background: "#DCF8C6",
          marginLeft: "auto",
        }}
      >
        {isFirstMessage && (
          <img
            className={styles.pointRight}
            src={require("../../assets/img/rightpoint.png")}
            alt="User"
          />
        )}
        <p>{message.message}</p>
        <div className={styles.chatBalloonArrow}></div>
        <span
          s
          style={{
            fontSize: "10px",
            fontWeight: "400",
            color: "#000",
            position: "absolute",
            left: 15,
            bottom: 2.5,
          }}
        >
          {formatTime(message.date)}
        </span>
      </div>

      {isFirstMessage && (
        <div className={styles.chatPhoto}>
          <img className={styles.rightImg} src={userPhoto} alt="User" />
        </div>
      )}
    </div>
  );
}

export default MyMessage;
