import React from "react";

import styles from "./billingPage.module.css";
import { FaFrown, FaQuestionCircle, FaTimesCircle } from "react-icons/fa";

import Accordion from "react-bootstrap/Accordion";

export default function FaqComponent() {
  return (
    <div className={styles.faq_section}>
      <h2 className={styles.faq_header}>
        <FaQuestionCircle className="mx-2" style={{ color: "#007bff" }} />{" "}
        Frequently Asked Questions
      </h2>
      <Accordion className="w-100 px-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h4 className={styles.faq_item}>
              <FaFrown className="mx-2" style={{ color: "#ffc107" }} /> What is
              the trial period and how does it work?
            </h4>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              You will have 7 days to test and make unlimited imports. If you
              find Ezy Task AI meets your needs, do nothing, and your
              subscription will automatically begin after the trial period,
              charging you for the plan you selected. To avoid charges, please
              cancel the subscription through PayPal before the 7th day.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <h4 className={styles.faq_item}>
              <FaFrown className="mx-2" style={{ color: "#dc3545" }} /> I'm not
              happy, can I have my money back?
            </h4>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              We have a 30-day no questions asked money-back guarantee. Simply
              drop us an email at{" "}
              <a
                href="mailto:support.ezytaskai@smartezy.com.br"
                style={{ textDecoration: "none", color: "#007bff" }}
              >
                support.ezytaskai@smartezy.com.br
              </a>
              , and we'll process your refund swiftly.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <h4 className={styles.faq_item}>
              <FaTimesCircle className="mx-2" style={{ color: "#17a2b8" }} />{" "}
              How can I cancel my subscription?
            </h4>
          </Accordion.Header>
          <Accordion.Body>
            <p>
              To cancel your subscription, please follow these instructions from
              PayPal: <br />
              <br />
              <strong>1</strong>. Log in to your PayPal account.
              <br />
              <strong>2</strong>. Click on "Settings" (gear icon) next to "Log
              out".
              <br />
              <strong>3</strong>. Select "Payments".
              <br />
              <strong>4</strong>. Click on "Manage automatic payments" under
              "Automatic payments".
              <br />
              <strong>5</strong>. Select the subscription you want to cancel and
              click on "Cancel".
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
