import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createErrorLog, getClientByEmail, getPlans } from "../../../api";

const initialState = {
  isAuth: false,
  plan: null,
  plans: [],
  plans_loading: false,
  errorOnAuthRequest: false,
  isClientFetched: false,
  monthTotalTasks: 0,
  monthUsedTasks: 0,
  allTimeUsedTasks: 0,
  qntTarefasPermitidasMes: 0,
};

export const fetchClientData = createAsyncThunk(
  "user/fetchClientData",
  async (_, { getState, rejectWithValue }) => {
    const { email } = getState().trello;
    try {
      const response = await getClientByEmail(email);

      return response;
    } catch (error) {
      const err = {
        message: error?.message,
        tracer: error,
        email,
        url: error?.url || "not found",
      };

      await createErrorLog(err);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchPlansData = createAsyncThunk(
  "user/fetchPlansData",
  async (_, { getState, rejectWithValue }) => {
    const { email } = getState().trello;
    try {
      const response = await getPlans();
      return response;
    } catch (error) {
      const err = {
        message: error?.message,
        tracer: error,
        email,
        url: error?.url || "not found",
      };

      await createErrorLog(err);
      return rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsPaid: (state, action) => {
      state.plan = action.payload.id;
      state.monthTotalTasks = action.payload.numberMaxTarefas;
      state.qntTarefasPermitidasMes = action.payload.numberMaxTarefas;
      state.qntTarefasGeradasMes = 0;
    },
    setPromptData: (state, action) => {
      state.monthTotalTasks = action.payload.qntTarefasDisponiveis || 0;
      state.monthUsedTasks = action.payload.qntTarefasGeradasMes || 0;
      state.allTimeUsedTasks = action.payload.qntTarefasUsuario || 0;
      state.qntTarefasPermitidasMes =
        action.payload.qntTarefasPermitidasMes || 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientData.pending, (state) => {
        state.errorOnAuthRequest = true;
      })
      .addCase(fetchClientData.fulfilled, (state, action) => {
        state.errorOnAuthRequest = false;
        const clientData = action.payload;
        if (clientData?.id) {
          state.monthTotalTasks = clientData.tarefas.qntTarefasDisponiveis || 0;
          state.monthUsedTasks = clientData.tarefas.qntTarefasGeradasMes || 0;
          state.allTimeUsedTasks = clientData.tarefas.qntTarefasUsuario || 0;
          state.qntTarefasPermitidasMes =
            clientData.tarefas.qntTarefasPermitidasMes || 0;
          state.isClientFetched = true;

          const hasActiveSubscription =
            clientData.assinaturasGetViewModels.some((sub) => sub.ativo);

          if (hasActiveSubscription) {
            const activeSubscription = clientData.assinaturasGetViewModels.find(
              (sub) => sub.ativo
            );
            const plan = activeSubscription.plano.id;
            state.isAuth = true;
            state.plan = plan;
          }
        }
      })
      .addCase(fetchClientData.rejected, (state) => {
        state.isClientFetched = true;
        state.errorOnAuthRequest = true;
      })
      .addCase(fetchPlansData.pending, (state) => {
        state.plans_loading = true;
        state.plansFetched = false;
      })
      .addCase(fetchPlansData.fulfilled, (state, action) => {
        state.plans_loading = false;
        state.plansFetched = true;
        state.plans = action.payload;
      })
      .addCase(fetchPlansData.rejected, (state) => {
        state.plans_loading = false;
        state.plansFetched = true;
        state.plans = [];
      });
  },
});

export const { setIsPaid, setPromptData } = userSlice.actions;
export default userSlice.reducer;
