import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TaskGeneratorComponent } from "../TaskGenerator";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./chatComponent.module.css";
import MyMessage from "./MyMessage";
import ChatMessage from "./ChatMessage";
import TempMessage from "./TempMessage";
import { IoMdCloseCircle } from "react-icons/io";
import { cleanChat } from "../../redux/reducers/chat/slice";

export function ChatComponent() {
  const dispatch = useDispatch();
  const { messages } = useSelector((state) => state.chat);
  const { isGeneratingTasks } = useSelector((state) => state.trello);
  const containerRef = useRef(null);

  useEffect(() => {
    const scrollToBottom = () => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    };

    const timeoutId1 = setTimeout(scrollToBottom, 200);
    const timeoutId2 = setTimeout(scrollToBottom, 1000);
    const timeoutId3 = setTimeout(scrollToBottom, 2500);
    const timeoutId4 = setTimeout(scrollToBottom, 3500);

    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
      clearTimeout(timeoutId3);
      clearTimeout(timeoutId4);
    };
  }, [messages]);

  useEffect(() => {
    let intervalId;
    if (isGeneratingTasks) {
      intervalId = setInterval(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }, 100);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isGeneratingTasks]);

  const handleCleanChat = () => {
    dispatch(cleanChat());
  };

  let previousSender = null;

  return (
    <>
      <div className={styles.container} ref={containerRef}>
        <div className={styles.overlay}>
          <IoMdCloseCircle
            onClick={handleCleanChat}
            className={styles.closeBtn}
          />
        </div>
        <TransitionGroup>
          {messages.map((m, index) => {
            const isFirstMessage = m.by !== previousSender;
            previousSender = m.by;

            const Component = m.by === "me" ? MyMessage : ChatMessage;
            const transitionClassName =
              m.by === "me"
                ? {
                    enter: styles.fadeEnterMyMessage,
                    enterActive: styles.fadeEnterActiveMyMessage,
                    exit: styles.fadeExitMyMessage,
                    exitActive: styles.fadeExitActiveMyMessage,
                  }
                : {
                    enter: styles.fadeEnter,
                    enterActive: styles.fadeEnterActive,
                    exit: styles.fadeExit,
                    exitActive: styles.fadeExitActive,
                  };

            return (
              <CSSTransition
                key={index}
                timeout={300}
                classNames={transitionClassName}
                unmountOnExit
              >
                <Component message={m} isFirstMessage={isFirstMessage} />
              </CSSTransition>
            );
          })}
        </TransitionGroup>
        {isGeneratingTasks && <TempMessage isFirstMessage={false} />}
      </div>
      <TaskGeneratorComponent />
    </>
  );
}

export default ChatComponent;
