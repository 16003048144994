import React, { useEffect } from "react";
import styles from "./billingPage.module.css";

import { Fade } from "react-bootstrap";
import Lottie from "lottie-react";
import Loading from "../../assets/lottie/animation.json";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfo } from "../../redux/reducers/trello/slice";
import {
  fetchClientData,
  fetchPlansData,
} from "../../redux/reducers/user/slice";

import FaqComponent from "./FAQ";
import PlansContainerComponent from "./PlansContainer";

const BillingPage = () => {
  const dispatch = useDispatch();

  const {
    isClientFetched,
    errorOnAuthRequest,
    plans,
    plans_loading,
    plansFetched,    
  } = useSelector((state) => state.user);
  const { email, getUserLoading, fetched } = useSelector((state) => state.trello);

  useEffect(() => {
    if (email && !isClientFetched) {
      dispatch(fetchClientData());
    }
  }, [email, isClientFetched, dispatch]);

  useEffect(() => {
    if (!fetched && !getUserLoading) {
      dispatch(fetchUserInfo());
    }

    if (!plans.length && !plans_loading && !plansFetched) {
      dispatch(fetchPlansData());      
    }
    // eslint-disable-next-line
  }, [dispatch, email, getUserLoading, plans, plans_loading, fetched]);

  const options = {
    loop: true,
    autoplay: true,
    style: { width: 125, height: 125, margin: "auto" },
    animationData: Loading,
  };

  if (!isClientFetched) {
    return (
      <Fade in appear>
        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
          <Lottie {...options} />
        </div>
      </Fade>
    );
  }

  return (
    <Fade in appear>
      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
        <div className={`${styles.description}`}>
          <p className="mb-0">
            <i className="fas fa-info-circle"></i> Once you subscribe to a plan,
            you will be able to use Ezy Task AI on any Trello board as the plan
            will be associated with your user (email).
          </p>
        </div>
        {errorOnAuthRequest && !email && (
          <h2 className={styles.faq_header}>
            Error retrieving data. Please try again.
          </h2>
        )}

        {plans_loading ? (
          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            <Lottie {...options} />
          </div>
        ) : (
          <PlansContainerComponent />
        )}

        <FaqComponent />
      </div>
    </Fade>
  );
};

export default BillingPage;
