import React, { useState, useEffect } from 'react';

const AnimatedCounter = ({ finalValue }) => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    let start = 0;
    const increment = finalValue / 100; // Ajuste o incremento conforme necessário
    const duration = 2000; // Duração da animação em milissegundos
    let interval;

    const animate = () => {
      interval = setInterval(() => {
        start += increment;
        if (start < finalValue) {
          setCurrent(Math.round(start));
        } else {
          setCurrent(finalValue);
          clearInterval(interval); // Limpa o intervalo quando atinge finalValue
        }
      }, duration / 100); // Intervalo ajustado para suavizar a animação
    };

    animate();

    return () => clearInterval(interval); // Limpa o intervalo quando o componente é desmontado
  }, [finalValue]);

  return (
    <div>
      <div>
        {current}
      </div>
    </div>
  );
};

export default AnimatedCounter;
