import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import { useSelector } from "react-redux";

function Layout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.includes("menu-signed");
  const { email, fetched } = useSelector((state) => state.trello);

  useEffect(() => {
    if (fetched && !email) {
      navigate('/auth')
    }
  }, [fetched, email]);

  return (
    <div className="mx-auto w-100">
      {!pathname && fetched && email && <NavBar />}
      {children}
    </div>
  );
}

export default Layout;
