import { combineReducers } from "@reduxjs/toolkit";
import trelloReducer from "./reducers/trello/slice";
import userReducer from "./reducers/user/slice";
import chatReducer from "./reducers/chat/slice";

const rootReducer = combineReducers({
  trello: trelloReducer,
  user: userReducer,
  chat: chatReducer,
});

export default rootReducer;
