import Offcanvas from "react-bootstrap/Offcanvas";
import { MdChat } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../utils";
import { useEffect } from "react";
import {
  fetchHistoryData,
  processAndSetHistoryChat,
} from "../../redux/reducers/chat/slice";
import { GrTask } from "react-icons/gr";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import styles from "./historicComponent.module.css";
import { BsMailbox2 } from "react-icons/bs";
import Lottie from "lottie-react";
import Loading from "../../assets/lottie/animation.json";


function HistoricComponent({ show, handleClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { history, loading, isHistoryFetched } = useSelector(
    (state) => state.chat
  );
  const { email } = useSelector((state) => state.trello);

  useEffect(() => {
    if ((email && !isHistoryFetched) || history.length === 0) {
      dispatch(fetchHistoryData());
    }

    // eslint-disable-next-line
  }, [email]);

  const options = {
    loop: true,
    autoplay: true,
    style: { width: 125, height: 125, margin: "auto" },
    animationData: Loading,
  };

  function sortHistoryByDate(history) {
    const historyCopy = [...history];
    return history?.length > 0
      ? historyCopy.sort(
          (a, b) => Date.parse(b.dateRegistro) - Date.parse(a.dateRegistro)
        )
      : history;
  }

  const sortedHistory = sortHistoryByDate(history);

  const handleSetConversation = (cv) => {
    handleClose();
    dispatch(processAndSetHistoryChat(cv));
    if (location.pathname !== "/task") {
      navigate("/task");
    }
  };

  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Messages</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
          className="offcanvas-body-custom px-0 pt-0 bodyCustom"
          style={{
            borderTop: "2px solid #c5c5c5",
          }}
        >
          {history.length > 0 &&
            !loading &&
            sortedHistory.map((hist) => (
              <div
                className={styles.messageBox}
                onClick={() => handleSetConversation(hist)}
                key={hist.id}
              >
                <div className={styles.promptBox}>
                  <MdChat
                    style={{ fontSize: 15, marginRight: 3 }}
                    data-tooltip-id="prompt-tooltip"
                    data-tooltip-content="Your Prompt"
                    data-tooltip-place="top"
                  />
                  <Tooltip id="prompt-tooltip" />
                  {hist.prompt}
                </div>

                <div className={styles.totalBox}>
                  <GrTask
                    style={{ fontSize: 14, marginRight: 3 }}
                    data-tooltip-id="task-tooltip"
                    data-tooltip-content="Total tasks"
                    data-tooltip-place="top"
                  />
                  <Tooltip id="task-tooltip" />
                  {hist.qntTarefas}
                </div>

                <span className={styles.promptDate}>
                  {" "}
                  {formatDate(hist.dateRegistro)}
                </span>
              </div>
            ))}

          {loading && !isHistoryFetched && <Lottie {...options} />}

          {history.length === 0 && isHistoryFetched && !loading && (
            <h3 className="text-center pt-3">
              No chat history found.
              <br /> <BsMailbox2 style={{ fontSize: 45 }} />
            </h3>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default HistoricComponent;
