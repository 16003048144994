import React from "react";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

function SelectTaskComponent({ onChange }) {
  const { isGeneratingTasks } = useSelector((state) => state.trello);
  const { monthTotalTasks } = useSelector((state) => state.user);
  
  const selectStyle = {
    border: "none",
    borderLeft: "2px solid #00000020",
    width: "11%",
    outline: "none",
    borderRadius: "0px",
    padding: "6px 10px",
    boxShadow: "none",
    height: "50px",
    position: "absolute",
    left: "69%",
  };

  const numbers = [20, 30, 40, 50, 100, 150, 200].filter(
    (n) => n < monthTotalTasks
  );

  return (
    <Form.Select
      size="md"
      className="custom-select"
      style={selectStyle}
      onChange={onChange}
      disabled={isGeneratingTasks}
    >
      <option value="">Task</option>
      {numbers.map((n) => (
        <option key={n} value={n}>
          {n}
        </option>
      ))}
    </Form.Select>
  );
}

export default SelectTaskComponent;
