import moment from "moment";

export function formatTime(date) {
  const now = moment();
  const inputDate = moment(date);
  const minutesAgo = now.diff(inputDate, "minutes");

  const isSameDay = now.isSame(inputDate, "day");

  if (minutesAgo < 1) {
    return "Just now";
  } else if (minutesAgo < 1) {
    return `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
  } else if (isSameDay) {
    return inputDate.format("HH:mm");
  } else {
    return inputDate.format("MMMM D, YYYY [at] HH:mm");
  }
}

export function getRandomPhrase() {
    const phrases = [
      "🤖 Hold on while I handle everything for you...",
      "⌛ Just a moment while I take care of it all for you...",
      "✍️ Please wait while I create the tasks for you...",
      "⏳ Hang tight while I set everything up for you...",
      "🛠️ Give me a second while I prepare your tasks on Trello...",
      "😊 Sit back and relax while I do the work for you...",
      "🧠 Let me think... I'll have it ready soon...",
      "🔧 Working on it! Almost there...",
      "📋 Organizing your tasks in a jiffy...",
      "🚀 Getting everything ready for you in no time..."
    ];
  
    const randomIndex = Math.floor(Math.random() * phrases.length);
    return phrases[randomIndex];
  }
  

export function getRandomErrorPhrase() {
  const phrases = [
    "An error occurred while creating tasks. Please try again. 🤖",
    "Something went wrong during task creation. We're working on it. 🤓",
    "Oops! There was an issue generating your tasks. Please try again later. 😔",
    "Error encountered while generating tasks. Please check your input and try again. 🤖",
    "Task creation failed due to an unexpected error. Please retry. 🤓",
    "We ran into an error while creating tasks. Please give it another go. 🤖",
    "There was a problem with task generation. Our team is on it. 😔",
    "Unfortunately, we couldn't create your tasks due to an error. Please attempt again. 🤖",
    "Task creation encountered an error. Kindly try again shortly. 😔",
  ];

  const randomIndex = Math.floor(Math.random() * phrases.length);
  return phrases[randomIndex];
}

export function formatDate(dateString) {
  const date = moment(dateString);
  const now = moment();

  if (now.diff(date, 'minutes') < 1) {
    return 'now';
  } else if (now.diff(date, 'hours') < 1) {
    const minutes = now.diff(date, 'minutes');
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else if (now.isSame(date, 'day')) {
    const hours = now.diff(date, 'hours');
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else if (now.isSame(date.add(1, 'day'), 'day')) {
    return 'yesterday';
  } else if (now.diff(date, 'days') < 7) {
    const days = now.diff(date, 'days');
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  } else {
    return date.format('MM/DD/YYYY');
  }
}

export const processChatMessages = async (tarefas, dateRegistro) => {
  const result = [];

  for (const msg of tarefas) {
    const taskname = {
      message: "**Task name**: "+msg.nome,
      date: dateRegistro,
      by: "chat",
    };

    const taskDesc = {
      message: "**Task description**: "+msg.descricao,
      date: dateRegistro,
      by: "chat",
    };

    result.push(taskname, taskDesc);  
    
  }

  return result;
};

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function promptValidation(prompt) {
  if (!prompt || prompt.trim() === "") {
    return false;
  }

  const words = prompt.trim().split(/\s+/);

  return words.some(word => word.length >= 3);
}