import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetSecret, createList, getUserInfo } from "../../../api/trello";
import { createErrorLog, createPrompt } from "../../../api/index";
import { createCards } from "../../../api/trello";
import { getRandomErrorPhrase, getRandomPhrase } from "../../../utils";
import { addMsg } from "../chat/slice";
import { setPromptData } from "../user/slice";

const completedMsg = `All tasks have been created in Trello! 🎉 Thank you for using our service. We appreciate your trust and look forward to helping you with your tasks in the future. Don't forget to come back and use it whenever you need. Happy organizing! 😊`;

export const fetchLists = createAsyncThunk(
  "trello/fetchLists",
  async (_, { getState, rejectWithValue }) => {
    const { email } = getState().trello;
    try {
      const t = window.TrelloPowerUp.iframe({
        appKey: "5a75b71bf26591fc4b0e75b4a30a5dde",
        appName: "Ezy Task AI",
      });

      const secret = await GetSecret(t);
      if (!secret) throw new Error("Failed to get Trello secret");

      window.Trello.setToken(secret);

      const board = await t.board("all");
      const boardId = board.id;

      const trelloLists = await new Promise((resolve, reject) => {
        window.Trello.get(
          `/boards/${boardId}/lists`,
          { fields: "id,name" },
          (lists) => {
            if (lists && lists.length > 0) {
              resolve(lists);
            } else {
              t.alert({
                message:
                  "🤖 We noticed that your board didn't have any lists, so we automatically created a list named 'To Do' to handle your imports.",
              });
              createList("To Do", boardId, secret)
                .then((newList) => resolve([newList]))
                .catch((createListError) => reject(createListError));
            }
          }
        );
      });

      return trelloLists.map((list) => ({
        name: list.name,
        value: list.id,
      }));
    } catch (error) {
      const err = {
        message: error?.message,
        tracer: error,
        email,
        url: error?.url || "not found",
      };

      await createErrorLog(err);
      return rejectWithValue(error.message);
    }
  }
);

export const generateTasks = createAsyncThunk(
  "trello/generateTasks",
  async (
    { prompt, listId, qntTasks },
    { getState, rejectWithValue, dispatch }
  ) => {
    const { email } = getState().trello;
    try {
      const newMessage = {
        message: getRandomPhrase(),
        date: new Date(),
        by: "chat",
      };

      dispatch(addMsg(newMessage));

      if (!email) {
        throw new Error("Email não disponível");
      }

      const data = await createPrompt(prompt, email, qntTasks);
      const tasks = data.tarefasPrompt;

      if (tasks?.length === 0) {
        const statsMsg = {
          message: `I'm sorry, I didn't quite understand your prompt. Could you please review it?`,
          date: new Date(),
          by: "chat",
        };

        dispatch(addMsg(statsMsg));
        return;
      }

      const t = window.TrelloPowerUp.iframe({
        appKey: "5a75b71bf26591fc4b0e75b4a30a5dde",
        appName: "Ezy Task AI",
      });

      await createCards(tasks, listId, t, dispatch);
      dispatch(setPromptData(data));
      const okMsg = {
        message: completedMsg,
        date: new Date(),
        by: "chat",
      };
      const statsMsg = {
        message: `You have used a total of ${tasks.length} tasks, and you have ${data.qntTarefasDisponiveis} remaining.`,
        date: new Date(),
        by: "chat",
      };

      dispatch(addMsg(okMsg));
      dispatch(addMsg(statsMsg));
      return true;
    } catch (error) {
      const newMessage = {
        message: getRandomErrorPhrase(),
        date: new Date(),
        by: "chat",
      };

      dispatch(addMsg(newMessage));

      const err = {
        message: error?.message,
        tracer: error,
        email,
        url: error?.url || "not found",
      };

      await createErrorLog(err);

      return rejectWithValue(error.message);
    }
  }
);

export const fetchUserInfo = createAsyncThunk(
  "trello/fetchUserInfo",
  async (_, { getState, rejectWithValue }) => {
    const { email } = getState().trello;
    try {
      const t = window.TrelloPowerUp.iframe({
        appKey: "5a75b71bf26591fc4b0e75b4a30a5dde",
        appName: "Ezy Task AI",
      });

      const userInfo = await getUserInfo(t);

      return userInfo;
    } catch (error) {
      const err = {
        message: error?.message,
        tracer: error,
        email,
        url: error?.url || "not found",
      };

      await createErrorLog(err);
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  name: "",
  email: "",
  image: "",
  fetched: false,
  listsFetched: false,
  getUserLoading: false,
  lists: [],
  listsLoading: false,
  isGeneratingTasks: false,
  genSuccess: false,
  genFailure: false,
};

const trelloSlice = createSlice({
  name: "trello",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLists.pending, (state) => {
        state.listsLoading = true;
        state.listsFetched = false;
      })
      .addCase(fetchLists.fulfilled, (state, action) => {
        state.listsLoading = false;
        state.listsFetched = true;
        state.lists = action.payload;
      })
      .addCase(fetchLists.rejected, (state) => {
        state.listsLoading = false;
        state.listsFetched = true;
        state.lists = [];
      })
      .addCase(generateTasks.pending, (state) => {
        state.isGeneratingTasks = true;
        state.genSuccess = false;
        state.genFailure = false;
      })
      .addCase(generateTasks.fulfilled, (state) => {
        state.isGeneratingTasks = false;
        state.genSuccess = true;
        state.genFailure = false;
      })
      .addCase(generateTasks.rejected, (state) => {
        state.isGeneratingTasks = false;
        state.genSuccess = false;
        state.genFailure = true;
      })
      .addCase(fetchUserInfo.pending, (state) => {
        state.getUserLoading = true;
        state.fetched = false;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.getUserLoading = false;
        state.fetched = true;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.image = action.payload.image;
      })
      .addCase(fetchUserInfo.rejected, (state) => {
        state.getUserLoading = false;
        state.fetched = true;
        state.name = null;
        state.email = null;
        state.image = "";
      });
  },
});

export default trelloSlice.reducer;
