import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { fetchLists } from "../../redux/reducers/trello/slice";

function SelectComponent({ onChange }) {
  const dispatch = useDispatch();
  const { lists, listsLoading, isGeneratingTasks } = useSelector((state) => state.trello);
  
  const selectStyle = {
    border: "none",  
    borderLeft: "2px solid #00000020",      
    width: "12%",    
    borderRadius: "0px",
    padding: "6px 9px",
    paddingRight: "27px",
    height: "50px",
    position: "absolute",
    left: "80%", 
    boxShadow: 'none', 
  };

  useEffect(() => {
    if (!lists.length) {
      dispatch(fetchLists());
    }
  }, [dispatch, lists]);

  return (
    <Form.Select
      size="md"
      className="custom-select"
      style={selectStyle}
      disabled={listsLoading || isGeneratingTasks}
      onChange={onChange}
    >
      <option value="">List</option>
      {lists.map((list) => (
        <option key={list.value} value={list.value}>
          {list.name}
        </option>
      ))}
    </Form.Select>
  );
}

export default SelectComponent;
