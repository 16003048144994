import { addMsg } from "../redux/reducers/chat/slice";

export function GetSecret(t) {
  return new Promise((resolve, reject) => {
    t.getRestApi()
      .getToken()
      .then((secret) => {
        let isSetToken = decodeURIComponent(secret).replace(/"/g, "");
        resolve(isSetToken);
      })
      .catch((err) => {
        resolve(null);
      });
  });
}

export const createCards = async (cardsArray, listId, t, dispatch) => {
  try {
    const secret = await GetSecret(t);
    window.Trello.setToken(secret);

    for (const cardInfo of cardsArray) {
      const cardData = {
        pos: "top",
        idList: listId,
        name: cardInfo.nome,
        desc: cardInfo.descricao,
      };

      try {
        await window.Trello.post("/cards", cardData);

        dispatch(
          addMsg({
            message: `Task name: ${cardInfo.nome}`,
            date: new Date(),
            by: "chat",
          })
        );
        await new Promise((resolve) => setTimeout(resolve, 50));
        dispatch(
          addMsg({
            message: `Task description: ${cardInfo.descricao}`,
            date: new Date(),
            by: "chat",
          })
        );

        await new Promise((resolve) => setTimeout(resolve, 650));
      } catch (error) {
        if (error.status === 429) {
          console.error("Rate limit exceeded. Retrying in 10 seconds...");
          await new Promise((resolve) => setTimeout(resolve, 10000));
        } else {
          console.error("Erro ao criar cartão:", error);
        }
      }
    }
  } catch (error) {
    console.error("Erro ao criar cartões:", error);
  }
};

export const getUserInfo = async (t) => {
  return GetSecret(t).then((secret) => {
    window.Trello.setToken(secret);
    const token = secret;

    return fetch(
      `https://api.trello.com/1/members/me?key=5a75b71bf26591fc4b0e75b4a30a5dde&token=${token}`
    )
      .then((response) => response.json())
      .then((data) => {
        return {
          name: data.fullName,
          email: data.email,
          image: data.avatarUrl,
        };
      })
      .catch((error) => {
        console.error(
          "Ocorreu um erro ao obter as informações do usuário:",
          error
        );
        throw error;
      });
  });
};


export async function createList(listName, boardId, token) {
  const url = `https://api.trello.com/1/lists?key=5a75b71bf26591fc4b0e75b4a30a5dde&token=${token}`;
  const listData = {
    name: listName,
    idBoard: boardId,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(listData),
    });

    if (!response.ok) {
      throw new Error(`Erro ao criar lista "${listName}": ${response.statusText}`);
    }

    const createdList = await response.json();
    return createdList;
  } catch (error) {   
    throw error;
  }
}