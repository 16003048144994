import React, { useEffect, useState } from "react";
import styles from "./taskPage.module.css";
import { CSSTransition } from "react-transition-group";
import { TaskGeneratorComponent } from "../../components/TaskGenerator";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfo } from "../../redux/reducers/trello/slice";
import Typewriter from "../../components/Typewriter";
import { PopularPrompts } from "../../components/PopularPrompts";
import { PromptData } from "../../components/PromptData";
import { ChatComponent } from "../../components/ChatComponent";

function TaskPage() {
  const dispatch = useDispatch();
  const { getUserLoading, fetched } = useSelector((state) => state.trello);
  const { messages } = useSelector((state) => state.chat);
  const [fadeOut, setFadeOut] = useState(false);
  const isChatVisible = messages.length > 0;
  const [typewriterText, setTypewriterText] = useState("");
  const [currentPromptIndex, setCurrentPromptIndex] = useState(0);

  const prompts = [
      "mastering data science.",
      "starting a YouTube channel.",
      "learning graphic design.",
      "improving your public speaking skills.",
      "crafting a business proposal.",
      "exploring vegan cooking.",
      "researching family genealogy.",
      "designing a minimalist lifestyle.",
      "studying for an MBA entrance exam.",
      "building a personal finance plan.",
      "learning to meditate.",
      "exploring digital art creation.",
      "volunteering for a local charity.",
      "writing a children’s book.",
      "starting a community garden.",
      "practicing yoga at home.",
      "learning to play the guitar.",
      "building a PC from scratch.",
      "creating a mindfulness blog.",
      "training for a marathon.",
      "learning to bake artisan bread.",
      "writing poetry.",
      "starting a fashion boutique online.",
      "creating a mobile game.",
      "exploring classical music.",
      "practicing photography.",
      "learning mixology.",
      "building a drone.",
      "studying architectural design.",
      "exploring interior decorating.",
      "creating handmade jewelry.",
      "learning to dance salsa.",
      "building a model railway.",
      "studying astrology.",
      "starting a pet grooming business.",
      "learning about AI and machine learning.",
      "preparing for a professional certification.",
      "learning to sew.",
      "studying philosophy.",
      "exploring astronomy.",
      "starting a tech vlog.",
      "learning calligraphy.",
      "developing a fitness app.",
      "creating an art portfolio.",
      "studying herbalism.",
      "planning a backpacking trip through Asia.",
      "designing eco-friendly clothing.",
      "learning about cryptocurrencies.",
      "building a vertical garden.",
      "starting a food truck business.",
      "learning to play chess.",
      "developing VR content.",
      "studying environmental science.",
      "exploring historical reenactments.",
      "learning to skateboard.",
      "creating a documentary film.",
      "studying wildlife conservation.",
      "learning to kite surf.",
      "starting a record label.",
      "exploring 3D printing.",
      "learning to sculpt.",
      "creating a local news podcast.",
      "planning a sustainable living community.",
      "studying renewable energy.",
      "learning to DJ.",
      "starting a vintage clothing shop.",
      "creating a board game.",
      "exploring paleontology.",
      "learning to quilt.",
      "developing an e-learning course.",
      "creating a comic book.",
      "studying marine biology.",
      "exploring space technology.",
      "learning to mountain bike.",
      "starting a craft brewery.",
      "creating a home automation system.",
      "studying geology.",
      "exploring mythologies.",
      "learning to pilot a plane.",
      "starting a health and wellness retreat.",
      "creating a tech startup."
  ];

  useEffect(() => {
    if (!fetched && !getUserLoading) {
      dispatch(fetchUserInfo());
    }
  }, [dispatch, fetched, getUserLoading]);

  useEffect(() => {
    if (isChatVisible) {
      setFadeOut(true);
    } else {
      setFadeOut(false);
    }
  }, [isChatVisible]);

  useEffect(() => {   
    setTypewriterText(prompts[currentPromptIndex]);

    const changeText = () => {
      const nextIndex = (currentPromptIndex + 1) % prompts.length;
      setCurrentPromptIndex(nextIndex);
      setTypewriterText(prompts[nextIndex]);
    };

    const intervalId = setInterval(() => {
      changeText();
    }, 10000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [currentPromptIndex]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <CSSTransition
          in={!fadeOut}
          timeout={200}
          classNames={{
            enter: styles.fadeEnter,
            enterActive: styles.fadeEnterActive,
            exit: styles.fadeExit,
            exitActive: styles.fadeExitActive,
          }}
          unmountOnExit
        >
          <>
          <div style={{ height: "180px", overflow: "hidden", width: "720px" }}>
            <h1 className={styles.stylish_text}>
              With Ezy Task AI, turn your ideas  <br />
              into action&nbsp;
                <Typewriter key={typewriterText} loop={false} cursor={true} text={typewriterText} />              
            </h1>
          </div>
            <PopularPrompts />
            <TaskGeneratorComponent />
            <PromptData />
            {isChatVisible && <ChatComponent />}
          </>
        </CSSTransition>

        {isChatVisible && <ChatComponent />}
      </div>
    </div>
  );
}

export default TaskPage;