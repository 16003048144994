import React from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Layout from "../pages/Layout";
import InstructionsPage from "../pages/Instructions";
import BillingPage from "../pages/Billing";
import TaskPage from "../pages/Task";
import { NotFoundComponent } from "./NotFoundComponent";
import SignedMenu from "../components/TrelloMenu/Signed";
import AuthPage from "../pages/Auth";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    children: [
      {
        path: "/",
        element: (
          <div className="p-5 text-center">
            <h3>Master</h3>
          </div>
        ),
      },
      { path: "/instructions", element: <InstructionsPage /> },      
      { path: "/billing", element: <BillingPage /> },
      { path: "/task", element: <TaskPage /> },
      { path: "/menu-signed", element: <SignedMenu /> },
      { path: "/auth", element: <AuthPage /> },
      {
        path: "*",
        element: <NotFoundComponent />,
      },
    ],
  },
]);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;
