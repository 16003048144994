import { Fade } from "react-bootstrap";
import styles from "./popularPromptsComponent.module.css";
import { useDispatch, useSelector } from "react-redux";
import AnimatedCounter from "../MonthlyProgress/AnimatedCounter";
import { useEffect } from "react";
import { fetchClientData } from "../../redux/reducers/user/slice";
import Loading from "../../assets/lottie/animation.json";
import Lottie from "lottie-react";
import { delay } from "../../utils";

export function PromptData() {
  const dispatch = useDispatch();
  const {
    monthTotalTasks,
    monthUsedTasks,
    allTimeUsedTasks,
    qntTarefasPermitidasMes,
    isClientFetched,
  } = useSelector((state) => state.user);

  const { email } = useSelector((state) => state.trello);

  const percentUsed = Math.round(
    (monthUsedTasks * 100) / (monthUsedTasks + monthTotalTasks)
  );

  const options = {
    loop: true,
    autoplay: true,
    style: { width: 75, height: 75, margin: "auto" },
    animationData: Loading,
  };

  useEffect(() => {
    if (email && !isClientFetched) {
      dispatch(fetchClientData());
    }

  }, [email, isClientFetched, dispatch]);

  return (
    <Fade in appear>
      <div className={styles.container}>
        <h4 className={styles.title}>Prompt in Numbers</h4>
        <div
          className="d-flex w-100"
          style={{ justifyContent: "space-between" }}
        >
          <div className={styles.data}>
            {!isClientFetched ? (
              <Lottie {...options} />
            ) : (
              <span>
                <AnimatedCounter finalValue={allTimeUsedTasks} />
              </span>
            )}
            <p>All time</p>
            <small>Total prompts created.</small>
          </div>
          <div className={styles.data}>
            {!isClientFetched ? (
              <Lottie {...options} />
            ) : (
              <span className="d-flex">
                <AnimatedCounter finalValue={monthTotalTasks} />{" "}
                <span style={{ fontSize: 33, alignSelf: "end" }}>/</span>
                <span
                  style={{ fontSize: 24, alignSelf: "end", marginLeft: "-2px" }}
                >
                  {qntTarefasPermitidasMes}
                </span>
              </span>
            )}
            <p>Monthly limit</p>
            <small>Available for use this month.</small>
          </div>
          <div className={styles.data}>
            {!isClientFetched ? (
              <Lottie {...options} />
            ) : (
              <span>
                <AnimatedCounter finalValue={monthUsedTasks} />
              </span>
            )}
            <p>This month</p>
            <small>Prompts created this month.</small>
          </div>

          <div className={styles.data}>
            {!isClientFetched ? (
              <Lottie {...options} />
            ) : (
              <span className="d-flex justify-content-center">
                <AnimatedCounter finalValue={percentUsed || 0} />
                <span style={{ fontSize: 22, marginTop: 19 }}> %</span>
              </span>
            )}
            <p>Monthly percentage</p>
            <small>Percentage of monthly limit used.</small>
          </div>
        </div>
      </div>
    </Fade>
  );
}
