import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getHistory } from "../../../api";
import { getRandomPhrase, processChatMessages } from "../../../utils";

export const fetchHistoryData = createAsyncThunk(
  "chat/fetchHistoryData",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { email } = getState().trello;
      const response = await getHistory(email);
      return response;
    } catch (err) {      
      return rejectWithValue(err.message);
    }
  }
);

export const processAndSetHistoryChat = createAsyncThunk(
  "chat/processAndSetHistoryChat",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const chatMsgs = await processChatMessages(
        payload.tarefas,
        payload.dateRegistro
      );
      const chatFirstMessage = {
        message: getRandomPhrase(),
        date: payload.dateRegistro,
        by: "chat",
      };
      const myMsg = {
        message: payload.prompt,
        date: payload.dateRegistro,
        by: "me",
      };

      return [myMsg, chatFirstMessage, ...chatMsgs];
    } catch (err) {      
      return rejectWithValue(err.message);
    }
  }
);

const initialState = {
  messages: [],
  history: [],
  isHistory: false,
  isHistoryFetched: false,
  loading: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addMsg: (state, action) => {
      if (state.isHistory) {
        state.isHistory = false;
      }
      if (state.isHistoryFetched) {
        state.isHistoryFetched = false;
        state.history = [];
      }
      state.messages.push(action.payload);
    },
    cleanChat: (state) => {
      state.messages = [];
      state.isHistory = false;
      state.isHistoryFetched = false;      
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistoryData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHistoryData.fulfilled, (state, action) => {
        state.loading = false;
        state.history = action.payload;
        state.isHistoryFetched = true;
      })
      .addCase(fetchHistoryData.rejected, (state) => {
        state.loading = false;
        state.isHistoryFetched = true;
      })
      .addCase(processAndSetHistoryChat.pending, (state) => {
        state.loading = true;
      })
      .addCase(processAndSetHistoryChat.fulfilled, (state, action) => {
        state.loading = false;
        state.isHistory = true;
        state.messages = action.payload;
      })
      .addCase(processAndSetHistoryChat.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { addMsg, cleanChat } = chatSlice.actions;
export default chatSlice.reducer;
