import { Fade } from "react-bootstrap";
import styles from "./popularPromptsComponent.module.css";

const iconStyle = {
  fontSize:26,
  width: 21,
  height: 21,
  position: "absolute",
  left: 6,
  top: 6,
  color: '#0079bf',
  border: '2.5px solid #fff',
  borderRadius: '50%'
};

export function PopularPrompts() {
  return (
    <Fade in appear>
      <div className={styles.container}>
        <h4 className={styles.title}>Popular Prompts</h4>
        <div
          className="d-flex w-100"
          style={{ justifyContent: "space-between" }}
        >
          <div className={styles.promptBox}>
            <img alt="icon" src="https://ambitious-coast-0247bc410.5.azurestaticapps.net/img/logo144x144px.png" style={iconStyle} />
            Create an e-commerce website
          </div>
          <div className={styles.promptBox}>
            <img alt="icon" src="https://ambitious-coast-0247bc410.5.azurestaticapps.net/img/logo144x144px.png" style={iconStyle} />
            Build a homemade solar power plant
          </div>
          <div className={styles.promptBox}>
            <img alt="icon" src="https://ambitious-coast-0247bc410.5.azurestaticapps.net/img/logo144x144px.png" style={iconStyle} />
            Plan a trip to Disney
          </div>
          <div className={styles.promptBox}>
            <img alt="icon" src="https://ambitious-coast-0247bc410.5.azurestaticapps.net/img/logo144x144px.png" style={iconStyle} />
            Learn the React programming language
          </div>
        </div>
      </div>
    </Fade>
  );
}
