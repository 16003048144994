import React, { useEffect, useState } from "react";
import { FaRocket } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";

const AuthPage = () => {
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const t = window.TrelloPowerUp.iframe({
      appKey: "5a75b71bf26591fc4b0e75b4a30a5dde",
      appName: "Ezy Task Ai",
    });

    const authorize = async () => {
      try {
        await t.getRestApi().authorize({
          name: "Ezy Task Ai",
          interactive: true,
          scope: "read,write,account",
          expiration: "never",
        });
        setAuthorized(true);
      } catch (error) {
        console.error("Error during authorization:", error);
      }
    };

    const authorizeButton = document.getElementById("connect");
    if (authorizeButton) {
      authorizeButton.addEventListener("click", authorize);
    }

    return () => {
      if (authorizeButton) {
        authorizeButton.removeEventListener("click", authorize);
      }
    };
  }, []);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "90vh" }}>
      {!authorized && (
        <div className="content" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <img src="./img/logo144x144px.png" alt="logo" width="110px" />
          <div className="row" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="col col-sm-12 text-center">
              <h2 style={{ fontWeight: 900 }}>
                Add <span style={{ textDecoration: "underline", textDecorationColor: "#008c23" }}>Ezy Task Ai</span> to your Trello Board
              </h2>
            </div>
          </div>

          <div className="row" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="col w-100" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <ul className="fa-ul">
                <li style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <FaRocket style={{ fontSize: "25px", marginRight: "10px" }} />
                  <h4>
                    Ezy Task Ai for your boards<span style={{ fontWeight: 900, fontSize: "25px", color: "#008c23" }}>.</span>
                  </h4>
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col col-sm-12 text-center" style={{ marginBottom: "21px" }}>
              <button
                id="connect"
                type="button"
                className="btn btn-success w-100"
                style={{ fontWeight: 700, backgroundColor: "#008c23" }}
              >
                Authorize
              </button>
            </div>
            <br />
            <span className="text-center" style={{ fontWeight: 300 }}>
              Any questions? Email us at{" "}
              <a href="mailto:support.ezypowerups@smartezy.com.br?subject=Support to:Ezy Temporary Card">
                support.ezypowerups@smartezy.com.br
              </a>{" "}
              :-)
            </span>
          </div>
        </div>
      )}

      {authorized && (
        <div className="content">
          <div className="row">
            <div className="col col-sm-12 text-center">
              <img src="./img/logo144x144px.png" alt="logo" width="110px" />
              <h2 style={{ fontWeight: 900 }}>
                <span style={{ textDecoration: "underline", textDecorationColor: "#008c23" }}>Ezy Task Ai</span> successfully added to your Trello Board
              </h2>
            </div>
          </div>

          <div className="row" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="col col-sm-8 offset-sm-2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <ul className="fa-ul">
                <li>
                  <h4 className="text-center">
                    <FaRocket /> Take advantage of Ezy Task Ai unique features<span style={{ fontWeight: 900, fontSize: "25px", color: "#008c23" }}>.</span>
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthPage;
