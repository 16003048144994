const baseUrl = `https://ms-ezypowerups-ezytaskai.azurewebsites.net/`;

export const createPrompt = async (prompt, email, qntTasks) => {
  try {
    const data = {
      prompt,
      clienteEmail: email,
      qntTasks,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(baseUrl + "tarefas", requestOptions);

    if (!response.ok) {
      throw new Error("Erro ao criar tarefas");
    }

    const responseData = await response.json();

    if (
      Array.isArray(responseData?.tarefasPrompt)
    ) {
      return responseData;
    } else {
      throw new Error("Erro ao criar tarefas");
    }
  } catch (error) {
    error.url = baseUrl + "tarefas";
    throw error;
  }
};

export const getClientByEmail = async (email) => {
  const url = `${baseUrl}clientes/assinaturas?email=${encodeURIComponent(
    email
  )}`;
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, requestOptions);

    if (!response?.ok) {
      console.error("Erro ao obter os dados:", response.status);
      return null;
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    error.url = url;
    return error;
  }
};

export const createClientOnEzDB = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  fetch(baseUrl + "clientes", requestOptions)
    .then((response) => {
      if (!response.ok) {
        console.error("Erro ao enviar os dados:", response.status);
      }
      return response.json();
    })
    .then((result) => {
      // console.log("Resposta:", result);
    })
    .catch((error) => {
      error.url = baseUrl + "clientes";
      throw new Error("Ocorreu um erro: " + error.message);
    });
};

export const getHistory = async (email) => {
  const url = `${baseUrl}tarefas/prompts?clienteEmail=${encodeURIComponent(
    email
  )}`;

  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, requestOptions);

    if (!response?.ok) {
      throw new Error("Erro na busca dos prompts: " + response.status);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    error.url = url;
    throw new Error("Erro na busca dos prompts: " + error.message);
  }
};

export const getPlans = async () => {
  const url = `${baseUrl}planos`;
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, requestOptions);

    if (!response?.ok) {
      throw new Error("Erro na busca dos prompts: " + response.status);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    error.url = url;
    throw new Error("Erro na busca dos prompts: " + error.message);
  }
};

export async function createErrorLog(erro) {
  const REACT_APP_ERROR_API_URL =
    "https://prod-21.northcentralus.logic.azure.com:443/workflows/8bfec99b659d4b0d9c5b2e5194bf96a0/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=d8_gDERBu9OT9u9uBZI-yMY5FEcfx97IjjiGKMqQfgU";

  try {
    const data = {
      produto: "EzyTaskAi",
      tx_erro_menssage: erro.message,
      tx_error_tracer: JSON.stringify(erro.tracer),
      tx_email_cliente: erro.email,
      tx_endpoint_error: erro.url,
    };

    const response = await fetch(REACT_APP_ERROR_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error response from server: ${errorText}`);
    }
  } catch (error) {
    throw new Error("Error creating error log");
  }
}
