import React, { useEffect } from 'react';
import styles from './instructionsPage.module.css';
import { Fade } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientData } from '../../redux/reducers/user/slice';
import { fetchUserInfo } from '../../redux/reducers/trello/slice';

function InstructionsPage() {
  const dispatch = useDispatch();
  const { isClientFetched } = useSelector(state => state.user);
  const { email, getUserLoading, fetched } = useSelector(state => state.trello);

  useEffect(() => {
    if (email && !isClientFetched) {
      dispatch(fetchClientData());
    }
  }, [email, isClientFetched, dispatch]);

  useEffect(() => {
    if (!fetched && !getUserLoading) {
      dispatch(fetchUserInfo());
    }
  }, [dispatch, fetched, getUserLoading]);

  return (
    <Fade in appear>
      <div className="container">
        <h2 className={styles.h2}>Unlock Project Potential with AI</h2>
        <p className={styles.p}>
          Introducing <strong>Ezy Task AI</strong>—your AI-powered assistant designed to optimize project management across diverse fields. Enter your plans, and let us guide you, whether you're a tech professional or exploring new territories.
        </p>
        <br />
        <h2 className={styles.h2}>How Does It Work?</h2>
        <p className={styles.p}>
          Describe your project goals and Ezy Task AI will outline all essential tasks, detailing each step.
          Check out these examples:
        </p>
        <ul className={styles.ul}>
          <li className={styles.li}>
            <strong>Example 1:</strong> "An e-commerce site"<br />
            Crucial tasks for setting up your online store are listed below.
            <br/>
            <div style={{ textAlign: "center" }}>
              <img src="/img/demo1.gif" style={{ width: "80%" }} alt="Demo" />
            </div>
          </li>
          <li className={styles.li}>
          <br/>
          <br/>
            <strong>Example 2:</strong> "A home solar power plant"<br />
            Steps for building your energy solution are outlined below.
            <div style={{ textAlign: "center" }}>
              <img src="/img/demo2.gif" style={{ width: "80%" }} alt="Demo" />
            </div>
            <br/>
          </li>
        </ul>
        <br />
        <h2 className={styles.h2}>Empower Your Career</h2>
        <p className={styles.p}>
          Customize Ezy Task AI for your career advancement. Describe your role and challenges, and receive a tailored task list.
        </p>
        <ul className={styles.ul}>
          <li className={styles.li}>
            <strong>Example 4:</strong> "I am a real estate agent in Michigan looking to inspire confidence."<br />
            Ezy Task AI will provide steps to enhance your skills and grow professionally.
            <div style={{ textAlign: "center" }}>
              <img src="/img/demo3.gif" style={{ width: "80%" }} alt="Demo" />
            </div>
            <br/>
            <br/>
          </li>
        </ul>
        <h2 className={styles.h2}>Need a little push to turn your idea into reality?</h2>
        <p className={styles.p}>
          Access your card, click on "Create Subtasks," and specify how many you need. Our AI analyzes the best path for you.
        </p>
        <div style={{ textAlign: "center" }}>
          <img src="/img/demo4.gif" style={{ width: "80%" }} alt="Demo" />
        </div>
        <br />
        <br/>
        <br/>
        <h3 className={styles.h3} style={{ textAlign: "center" }}>
          Unlock your potential with Ezy Task AI: have fun, learn, and connect with the world of Artificial Intelligence! A journey of discovery awaits you!
        </h3>
        <br />
        <br/>
        <br/>
        <br/>
        <h2 className={styles.h2}>Understand the Challenges of Generative AI</h2>
        <p className={styles.p}>
          Be aware of potential errors and limitations when utilizing generative AI systems like Ezy Task AI. Here’s what you might encounter:
        </p>
        <ul className={styles.ul}>
          <li><strong>Comprehension Errors:</strong> Misinterpretations or inadequate outcomes due to context misunderstanding.</li>
          <li><strong>Data Limitations:</strong> Limitations in diversity or quality of training data can affect results.</li>
          <li><strong>Constant Updates:</strong> AI technologies evolve, necessitating ongoing adaptations.</li>
          <li><strong>Unconscious Bias:</strong> Biases in training data can affect the neutrality of results.</li>
          <li><strong>Technology Dependency:</strong> Over-reliance on AI can diminish critical thinking and decision-making.</li>
        </ul>
        <p className={styles.p}>
          It's crucial to use these tools with a clear understanding of their capabilities and limitations, always reviewing and adjusting the outputs as necessary.
        </p>
        <div className={styles.footer}>
          <p>
            EzyTaskAI is a product of SmartEzy. For assistance or inquiries,
            please email us at{" "}
            <a href="mailto:support.ezytaskai@smartezy.com.br">
            support.ezytaskai@smartezy.com.br
            </a>
            .
          </p>
        </div>
      </div>      
    </Fade>
  );
}

export default InstructionsPage;
