import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaBook, FaDollarSign, FaRobot } from "react-icons/fa";
import styles from "./navbar.module.css";
import { useSelector } from "react-redux";
import { BsFillChatRightFill } from "react-icons/bs";
import HistoricComponent from "../HistoricComponent";

export function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuth } = useSelector((state) => state.user);
  const [isActive, setIsActive] = useState(location.pathname);
  const [isHistoricVisible, setIsHistoricVisible] = useState(false);

  useEffect(() => {
    setIsActive(location.pathname);
  }, [location]);

  const routes = [
    {
      name: "Generate Tasks",
      route: "/task",
      visible: isAuth,
      icon: <FaRobot className={styles.navLinkIcon} />,
    },
    {
      name: "Billing",
      route: "/billing",
      visible: true,
      icon: <FaDollarSign className={styles.navLinkIcon} />,
    },
    {
      name: "Instructions",
      route: "/instructions",
      visible: true,
      icon: <FaBook className={styles.navLinkIcon} />,
    },
  ];

  const handleGoToPage = (page) => {
    navigate(page);
  };

  return (
    <nav className={styles.navbar}>
      {routes.map((r) =>
        r.visible ? (
          <div key={r.route} className={styles.navItem}>
            <div
              onClick={() => handleGoToPage(r.route)}
              className={`${styles.navLink} ${
                isActive === r.route ? styles.navLinkActive : ""
              }`}
            >
              {r.icon} {r.name}
            </div>
          </div>
        ) : null
      )}

      {isAuth && (
        <div
          onClick={() => setIsHistoricVisible(!isHistoricVisible)}
          className={styles.navItem}
        >
          <div className={styles.navLink}>
            <BsFillChatRightFill className={styles.navLinkIcon} /> Messages
          </div>
        </div>
      )}
      {isHistoricVisible && (
        <HistoricComponent
          show={isHistoricVisible}
          handleClose={() => setIsHistoricVisible(!isHistoricVisible)}
        />
      )}
    </nav>
  );
}

export default NavBar;
