import React, { useMemo } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { motion } from "framer-motion";
import styles from "./billingPage.module.css";
import { FaCalendarCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { createClientOnEzDB } from "../../api";
import { useNavigate } from "react-router-dom";
import { setIsPaid } from "../../redux/reducers/user/slice";

const PlansContainerComponent = () => {
  const dispatch = useDispatch();
  const redirectTo = useNavigate();
  const { plan, plans } = useSelector((state) => state.user);
  const { email, name } = useSelector((state) => state.trello);

  const initialOptions = {
    clientId:
      "AVsp9iUPiVXDxDb4uzQxF07lGFnixYQOynuJHvBJn0boa-JjBitCjD6lyASlReEBQUlFaJ0k6NdtMv0t",
    vault: true,
    intent: "subscription",
  };

  const createSubscription = (data, actions, p) => {
    return actions.subscription.create({
      plan_id: p.externoMetodoPagamentoId,
    });
  };

  const onApprove = async (data, p) => {
    const clientData = {
      name: name,
      textEmail: email,
      assinatura: [
        {
          name: "EzyTaskAI",
          metodoPagamentoId: 1,
          textIdentificadorMetodoPagamento: data.subscriptionID,
          periodicidadeId: 1,
          planoId: p.id,
        },
      ],
    };

    try {
      await createClientOnEzDB(clientData);
      dispatch(setIsPaid(p));
      return redirectTo("/task");
    } catch (err) {
      console.error(err);
    }
  };

  const onCancel = (data) => {
    console.error("Subscription cancelled", data);
  };

  const onError = (err) => {
    console.error("Error during subscription process", err);
  };

  const mappedPlans = useMemo(() => {
    return plans.map((p, index) => {
      if(plan && p.id < plan){
        return <></>
      }
      return p.externoMetodoPagamentoId !== "Gratuito" ? (
        <motion.div
          key={p.id}
          className={styles.plan}
          initial={{ opacity: 0, y: 20 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              delay: index * 0.1,
              duration: 0.5,
              ease: "easeInOut",
            },
          }}
          whileHover={{
            scale: 1.05,
            transition: { type: "spring", stiffness: 300 },
          }}
          whileTap={{ scale: 0.95 }}
        >
          <h2>
            <FaCalendarCheck /> {p.name}
          </h2>
          {p.id !== plan && <h4 className="m-1">${p.value}</h4>}
          <p className="m-1">
            <strong>{p.numberMaxTarefas}</strong> <em>tasks per month</em>
          </p>

          <div
            className={styles.paypal_button_container}
            id={`paypal-button-container-${p.id}`}
          >
            {(!plan || plan !== p.id) && (
              <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                  style={{
                    shape: "rect",
                    color: "gold",
                    layout: "vertical",
                    label: "subscribe",
                  }}
                  createSubscription={(data, actions) =>
                    createSubscription(data, actions, p)
                  }
                  onApprove={(data) => onApprove(data, p)}
                  onCancel={onCancel}
                  onError={onError}
                />
              </PayPalScriptProvider>
            )}
            {plan === p.id && (
              <span className={styles.span_sub}>
                Congratulations, you already have a subscription to Ezy Task AI.
              </span>
            )}
          </div>
        </motion.div>
      ) : (
        <></>
      );
    });
  }, [plans, plan, name, email]);

  return (
    <div className={styles.plan_container}>
      {!!email && !!name && mappedPlans}
    </div>
  );
};

export default PlansContainerComponent;
