import React, { useState } from "react";
import { MdSend } from "react-icons/md";
function ButtonComponent({ onClick, disabled }) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    onClick();
    setClicked(true);
    setTimeout(() => setClicked(false), 150);
  };
  const style = {
    background: disabled
      ? "#ccc"
      : clicked
      ? "linear-gradient(55deg, #0079bf, #0079b0)"
      : "#000",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    width: "60px",
    height: "50px",
    border: "none",    
    fontSize: "14px",
    fontWeight: "bold",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    cursor: disabled ? "not-allowed" : "pointer",
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={style}
      onClick={!disabled ? handleClick : null}      
    >
      <MdSend
        style={{
          fontSize: disabled ? "25px" : "35px",
          margin: "auto",
          color: disabled
            ? "linear-gradient(#888, #000)"
            : "#fff",
          transition: "font-size 0.5s, color 1s",
          transform: clicked ? "scale(0.90)" : "scale(1)",
        }}
      />
    </div>
  );
}

export default ButtonComponent;
